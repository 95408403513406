<template>
  <CRow>
    <CCol col="12" class="mb-2">
      <CButton
        class="float-right"
        color="success"
        @click="() => $router.push('/badges/0')"
      >
        Nueva medalla
      </CButton>
    </CCol>

    <CCol col="12">
      <CCard>
        <CCardHeader> Medallas </CCardHeader>
        <CCardBody>
          <div class="text-center">
            <CSpinner v-if="loading" variant="grow" size="lg" />
          </div>

          <CDataTable
            v-if="!loading"
            hover
            striped
            sorter
            :items="items"
            :fields="fields"
            clickable-rows
            @row-clicked="rowClicked"
          >
            <template #frecuency="data">
              <td>
                {{ data.item.frecuency | frecuencyLabel }}
              </td>
            </template>
            <template #end_date="data">
              <td>
                {{ data.item.end_date ? data.item.end_date : "" }}
              </td>
            </template>
            <template #type="data">
              <td>
                {{ data.item.type | typeLabel }}
              </td>
            </template>
            <template #type_option="data">
              <td>
                {{ data.item.type_option | typeOptionLabel }}
              </td>
            </template>
            <template #created_at="data">
              <td>
                {{ data.item.created_at | dateFormat }}
              </td>
            </template>
            <template #role="data">
              <td>
                {{ data.item.role ? data.item.role : '' }}
              </td>
            </template>
            <template #value="data">
              <td>
                {{ data.item.value }}
              </td>
            </template>
          </CDataTable>

          <CPagination
            v-if="!loading"
            :activePage.sync="page"
            :pages="lastPage"
            size="sm"
            align="center"
            @update:activePage="pageChange"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Resource from "@/api/resource";
import moment from "moment";
import { frecuencies, types, typeOptions } from "./data";

const resource = new Resource("badges");

export default {
  name: "Badges",
  data() {
    return {
      items: [],
      loading: false,
      fields: [
        { key: "name", label: "Nombre", _classes: "font-weight-bold" },
        { key: "role", label: "Rol" },
        { key: "frecuency", label: "Frecuencia" },
        { key: "start_date", label: "Fecha de inicio" },
        { key: "end_date", label: "Fecha final" },
        { key: "type", label: "Tipo" },
        { key: "type_option", label: "Opción" },
        { key: "value", label: "Valor" },
        { key: "created_at", label: "Creado" },
      ],
      page: 1,
      perPage: 10,
      lastPage: 1,
    };
  },

  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
    frecuencyLabel: function (value) {
      let label = "";
      if (frecuencies && frecuencies.length > 0) {
        const frecuency = frecuencies.filter((item) => item.value == value);
        label = frecuency[0].label;
      }
      return label;
    },
    typeLabel: function (value) {
      let label = "";
      if (types && types.length > 0) {
        const type = types.filter((item) => item.value == value);
        label = type[0].label;
      }
      return label;
    },
    typeOptionLabel: function (value) {
      let label = "";
      if(value){
        if (typeOptions && typeOptions.length > 0) {
          const typeOption = typeOptions.filter((item) => item.value == value);
          label = typeOption[0].label;
        }
      }
      return label;
    },
  },

  mounted() {
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getItems();
  },

  methods: {
    async getItems() {
      this.loading = true;
      try {
        const response = await resource.list({
          page: this.page,
          perPage: this.perPage,
        });
        if (response.status == 200) {
          this.items = response.data.data;
          this.lastPage = response.data.last_page;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    rowClicked(item, index) {
      this.$router.push({ path: `badges/${item.id}` });
    },

    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      await this.getItems();
      this.page = val;
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
